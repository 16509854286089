import { Grid } from '@mui/material';
import React from 'react';
import { IServiceResponse } from '../../../hooks/HookService';
import { IEmployeesFinancialReports } from '../../../interfaces/Reports';
import VisitAmountsReportSummaryWidget from './VisitAmountsReportSummaryWidget';
import VisitQuantityReportSummaryWidget from './VisitQuantityReportSummaryWidget';

const EmployeesReportsWidgets = ({
  data,
  error,
  errorMessage,
  isLoading,
  loadingNumber,
}: IServiceResponse<IEmployeesFinancialReports>) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <VisitQuantityReportSummaryWidget
          data={data}
          isLoading={isLoading}
          error={error}
          errorMessage={errorMessage}
          loadingNumber={loadingNumber}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <VisitAmountsReportSummaryWidget
          data={data}
          isLoading={isLoading}
          error={error}
          errorMessage={errorMessage}
          loadingNumber={loadingNumber}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeesReportsWidgets;
