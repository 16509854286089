/* eslint-disable camelcase */
/* eslint-disable new-cap */
import { Card } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { StyledBox } from '../styles/TabsStyles';
import Table from './Table';
import { IAttachment } from '../interfaces/attachment';
import attachmentService from '../services/attachemntService';
import useService from '../hooks/HookService';
import ErrorMessage from './ErrorMessage';
import { useTranslation } from 'react-i18next';
import MoreOptions from './MoreOptions';
import { Icon } from '@iconify/react';
import { theme } from '../theme';
import AttachmentsTableHeader from './AttachmentsTableHeader';
import DeleteIcon from '@mui/icons-material/Delete';
import FetcherService from '../hooks/FetcherService';
import DialogActions from './DialogActions';
import { IPatient, IPatientProgram, IUser } from '../interfaces/Models';
import download from '@iconify/icons-eva/download-outline';
import FileView from './FileView';
import { downloadCloudinaryAttachment, getFileType } from '../utils/cloudinary';
import { getDateFormatted } from '../utils/Helper';
import editFill from '@iconify/icons-eva/edit-fill';
import EditAttachmentNameModal from './EditAttachmentNameModal';
import { AbilityContext } from '../casl/can';
import { useAbility } from '@casl/react';
import { AbilitySubjects } from '../casl/ability';
import useSearchFilter from '../hooks/useSearchFilter';
import { IFolder } from '../interfaces/folder';
import AttachmentNameView from './AttachmentNameView';

interface IAttachmentsTable {
  isLoading?: boolean;
  patient?: IPatient;
  employee?: IUser;
  program?: IPatientProgram;
  isProfileEmployee?: boolean;
  folder?: IFolder;
  enableSearch?: boolean;
}

const AttachmentsTable = ({
  isLoading,
  patient,
  employee,
  program,
  isProfileEmployee = false,
  folder,
  enableSearch = false,
}: IAttachmentsTable) => {
  const { t } = useTranslation();
  const { pageInfo } = useSearchFilter();
  const { search } = pageInfo;
  const ability = useAbility(AbilityContext);
  const adminAbility = ability.can('manage', AbilitySubjects.ADMIN_ABILITY);

  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [record, setRecord] = useState<IAttachment | null>(null);
  const [openChangeFileName, setOpenChangeFileName] = useState<boolean>(false);
  const [isLoadingNewFileName, setIsLoadingNewFileName] = useState<boolean>(false);
  const [isLoadingDeleteAttachments, setIsLoadingDeleteAttachment] = useState<boolean>(false);

  const {
    isLoading: tableDataLoading,
    data,
    error,
    errorMessage,
  } = useService<IAttachment[]>({
    service: attachmentService.getAll({
      patient: patient?._id,
      employee: employee?._id,
      program: program?._id,
      folder: folder?._id,
      search,
    }),
    deps: [shouldTableUpdate, search],
  });

  const onOpenDeleteModal = (value: IAttachment) => {
    setRecord(value);
    setOpenDeleteModal(true);
  };

  const onOpenChangeFileNameModal = (value: IAttachment) => {
    setRecord(value);
    setOpenChangeFileName(true);
  };

  const handleModalClose = () => {
    setOpenDeleteModal(false);
    setOpenChangeFileName(false);
    setRecord(null);
  };

  const copyAttachmentUrl = (val: IAttachment) => navigator.clipboard.writeText(val.url);

  const columns = [
    {
      Header: `${t('global.attachments.fileName')}`,
      accessor: (val: any) => <AttachmentNameView attachment={val} />,
    },
    {
      Header: `${t('global.attachments.type')}`,
      accessor: (val: any) => {
        const { publicId, format } = val;
        const fileFormat = getFileType({ public_id: publicId, format });
        return fileFormat;
      },
    },
    {
      Header: `${t('global.attachments.view')}`,
      accessor: (val: any) => <FileView {...val} />,
    },
    {
      Header: `${t('global.attachments.uploadDate')}`,
      accessor: (val: any) => getDateFormatted(val.createdAt),
    },
    {
      Header: t('clinic.table.actions'),
      accessor: (val: any) => {
        const adminActionVisibility = adminAbility || isProfileEmployee;
        return (
          <MoreOptions
            actions={[
              {
                name: t('global.copyUrl'),
                handler: () => copyAttachmentUrl(val),
                icon: (
                  <Icon
                    width={24}
                    height={24}
                    icon="carbon:copy-link"
                    color={theme.palette.secondary.main}
                  />
                ),
              },
              {
                name: t('global.reportActions.downloadReport'),
                handler: () => downloadCloudinaryAttachment(val),
                icon: <Icon icon={download} width={24} height={24} color="#3E94A8" />,
              },
              {
                name: t('global.attachments.editName'),
                handler: onOpenChangeFileNameModal,
                icon: (
                  <Icon
                    icon={editFill}
                    width={24}
                    height={24}
                    color={theme.palette.secondary.main}
                  />
                ),
                isVisible: adminActionVisibility,
              },
              {
                name: t('global.moreMenu.delete'),
                handler: onOpenDeleteModal,
                icon: <DeleteIcon width={24} height={24} color="error" />,
                variant: theme.palette.error.main,
                isVisible: adminActionVisibility,
              },
            ]}
            record={val}
          />
        );
      },
    },
  ];

  const handleTableUpdate = () => setShouldTableUpdate((pre) => pre + 1);

  const handleDeleteAttachment = async () => {
    await FetcherService({
      service: attachmentService.deleteByRecordId(record?._id as string),
      errorMessage: t('global.attachments.deleteError'),
      setLoading: setIsLoadingDeleteAttachment,
    });
    setOpenDeleteModal(false);
    setShouldTableUpdate((prev) => prev + 1);
  };

  const handleEditAttachment = async (values: IAttachment) => {
    if (record) {
      await FetcherService<any>({
        service: attachmentService.updateByRecordId(record?._id || 'empty', {
          ...record,
          fileName: values.fileName,
        }),
        setLoading: setIsLoadingNewFileName,
      });
    }
    handleModalClose();
    handleTableUpdate();
  };

  const attachments = useMemo(() => data || [], [data]);

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  return (
    <>
      {record && (
        <EditAttachmentNameModal
          open={openChangeFileName}
          handleCloseModal={handleModalClose}
          record={record}
          handleSubmitForm={handleEditAttachment}
          isLoading={isLoadingNewFileName}
        />
      )}
      <DialogActions
        onClose={handleModalClose}
        open={openDeleteModal}
        dialog={`${t('global.attachments.deleteAttachment')}`}
        action={handleDeleteAttachment}
        loading={isLoadingDeleteAttachments}
      />
      <Card>
        <StyledBox>
          <Table
            columns={columns}
            isLoading={isLoading || tableDataLoading}
            data={attachments}
            count={attachments.length}
            pageSizes={[10]}
            TableHeaderFilters={
              <AttachmentsTableHeader
                patient={patient}
                employee={employee}
                program={program}
                handleTableUpdate={handleTableUpdate}
                isProfileEmployee={isProfileEmployee}
                folder={folder}
                enableSearch={enableSearch}
              />
            }
            showFilters={true}
            showPagination={true}
          />
        </StyledBox>
      </Card>
    </>
  );
};

export default AttachmentsTable;
