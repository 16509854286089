import React from 'react';
import i18n, { t } from 'i18next';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StyledFormControl } from '../../../styles/FormFieldsStyles';
import { Field } from 'formik';
import { MobileDatePicker } from 'formik-mui-lab';
import { he, enUS } from 'date-fns/locale';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TextField } from '@mui/material';

const DueDateFormField = () => {
  return (
    <StyledFormControl sx={{ mt: 1 }}>
      <LocalizationProvider locale={i18n.language == 'he' ? he : enUS} dateAdapter={AdapterDateFns}>
        <Field
          component={(props: any) => (
            <MobileDatePicker
              components={{
                LeftArrowIcon: KeyboardArrowRightIcon,
                RightArrowIcon: KeyboardArrowLeftIcon,
              }}
              {...props}
              cancelText={t('clinic.form.cancel')}
              okText={t('global.filters.ok')}
              renderInput={(props: any) => <TextField sx={{ direction: 'ltr' }} {...props} />}
            />
          )}
          label={t('visit.table.dueDate')}
          name="dueDate"
          inputFormat="dd/MM/yyyy"
        />
      </LocalizationProvider>
    </StyledFormControl>
  );
};

export default DueDateFormField;
