import { Box, Button } from '@mui/material';
import SearchFilter from '../../components/SearchFilter';
import { URL_QUERY_PARAMS, USER_STATUS_FILTER_VALUES } from '../../common/Constants';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DropDownFilter from '../../components/DropDownFilter';

const statusFilterValues = Object.values(USER_STATUS_FILTER_VALUES);

export default function UserTableHeader() {
  const { t } = useTranslation();

  const statusFilterKeys = [
    t(`global.status.${USER_STATUS_FILTER_VALUES.ACTIVE}`),
    t(`global.status.${USER_STATUS_FILTER_VALUES.INACTIVE}`),
  ];
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        alignItems: ['flex-start', 'center'],
        direction: 'ltr',
      }}
    >
      <SearchFilter />

      <Box
        sx={{
          width: ['100%', '50%'],
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          justifyContent: ['flex-start', 'flex-end'],
          alignItems: 'center',
          mt: [1, 0],
        }}
      >
        <Box>
          <DropDownFilter
            values={statusFilterValues}
            keys={statusFilterKeys}
            defaultValue={USER_STATUS_FILTER_VALUES.ACTIVE}
            urlParam={URL_QUERY_PARAMS.STATUS}
          />
        </Box>
        <Button
          variant="contained"
          component={RouterLink}
          to={'/app/user/new'}
          size="medium"
          endIcon={<Icon icon={plusFill} />}
          sx={{ p: 1 }}
        >
          {t('user.mainView.add')}
        </Button>
      </Box>
    </Box>
  );
}
