import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useService from '../../hooks/HookService';
import { IFolder } from '../../interfaces/folder';
import folderService from '../../services/folderService';
import ErrorMessage from '../../components/ErrorMessage';
import OverlaySpinner from '../../components/OverlaySpinner';
import Page from '../../components/Page';
import { Container } from '@mui/material';
import AttachmentsTable from '../../components/AttachmentsTable';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export default function FolderPage() {
  const { t } = useTranslation();
  const { folderId } = useParams();

  const { isLoading, data, error, errorMessage } = useService<IFolder>({
    service: folderService.getOneById(String(folderId)),
    deps: [],
  });

  const folder = useMemo(() => data, [data]);
  const title = useMemo(() => folder?.name, [folder]);

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (isLoading) {
    return <OverlaySpinner />;
  }

  return (
    <Page title={title}>
      <Container>
        <HeaderBreadcrumbs
          heading={t('drawer.sidebar.folders')}
          links={[
            { name: t('drawer.sidebar.folders'), href: `/app/folder` },
            { name: `${folder?.name}` },
          ]}
        />
        <AttachmentsTable folder={folder} enableSearch />
      </Container>
    </Page>
  );
}
