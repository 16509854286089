import { IQuery, IService } from '../interfaces/index';
import { HTTP_METHODS } from '../common/APIHelper';
import { IProgramReferral } from '../interfaces/referral';

const BASE_PATH = '/api/program-referral';

const getAll = (query?: IQuery): IService => {
  return { route: `${BASE_PATH}`, method: HTTP_METHODS.GET, query };
};

const add = (payload: IProgramReferral): IService => {
  return { route: `${BASE_PATH}`, method: HTTP_METHODS.POST, payload };
};

const deleteOneById = (id: string): IService => {
  return { route: `${BASE_PATH}/${id}`, method: HTTP_METHODS.DELETE };
};

const downloadOneById = (id: string, query?: IQuery): IService => {
  return {
    route: `${BASE_PATH}/${id}/download`,
    method: HTTP_METHODS.GET,
    query,
    responseType: 'blob',
  };
};

const programReferralService = {
  add,
  getAll,
  downloadOneById,
  deleteOneById,
};

export default programReferralService;
