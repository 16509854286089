import { AbilitySubjects } from '../../casl/ability';
import { INavItemConfig } from '../../components/NavSection';

const renderImage = (name: string) => (
  <img src={require(`../../assets/imgs/${name}.png`).default} alt={`${name}`} />
);

const sidebarConfig = (t: Function): INavItemConfig[] => {
  return [
    {
      title: t('drawer.sidebar.dashboard'),
      path: '/app/dashboard',
      icon: renderImage('data-management'),
      abilityName: AbilitySubjects.ALL,
    },
    {
      title: t('drawer.sidebar.users'),
      path: '/app/user',
      icon: renderImage('medical-team'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('drawer.sidebar.patients'),
      path: '/app/patient',
      icon: renderImage('patient'),
      abilityName: AbilitySubjects.ALL,
    },
    {
      title: t('drawer.sidebar.activePatients'),
      path: '/app/patient/patients-by-employee?active=true',
      icon: renderImage('patient'),
      abilityName: AbilitySubjects.EMPLOYEES_ABILITY,
    },
    {
      title: t('drawer.sidebar.clinic'),
      path: '/app/clinic',
      icon: renderImage('hospital'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('drawer.sidebar.allVisits'),
      path: '/app/visits/all',
      icon: renderImage('appointment'),
      abilityName: AbilitySubjects.ALL,
    },

    {
      title: t('global.pages.programs'),
      path: '/app/programs',
      icon: renderImage('medical-history'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('global.pages.activePrograms'),
      path: '/app/programs/programs-by-employee',
      icon: renderImage('medical-history'),
      abilityName: AbilitySubjects.EMPLOYEES_ABILITY,
    },
    {
      title: t('program.releaseReports.header'),
      path: '/app/release-reports',
      icon: renderImage('medical-history'),
      abilityName: AbilitySubjects.ALL,
    },
    {
      title: t('program.releaseReports.hospis'),
      path: '/app/release-reports/hospis',
      icon: renderImage('medical-history'),
      abilityName: AbilitySubjects.ALL,
    },
    {
      title: t('global.profile.accounting'),
      path: '/app/accounting',
      icon: renderImage('medical-history'),
      abilityName: AbilitySubjects.EMPLOYEES_ABILITY,
    },
    {
      title: t('financialReports.employees.header'),
      path: '/app/financial-reports/employees',
      icon: renderImage('dollar'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('financialReports.visitsDelayCalculation.reportName'),
      path: '/app/financial-reports/employee/visits/delay/calculation',
      icon: renderImage('saving'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('financialReports.programs.header'),
      path: '/app/financial-reports/programs',
      icon: renderImage('profit'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('systemReports.header'),
      path: '/app/system-reports',
      icon: renderImage('business-report'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('report.leumitReport'),
      path: '/app/leumit-reports',
      icon: renderImage('business-report'),
      abilityName: AbilitySubjects.ADMIN_ABILITY,
    },
    {
      title: t('drawer.sidebar.folders'),
      path: '/app/folder',
      icon: renderImage('folder'),
      abilityName: AbilitySubjects.ALL,
    },
  ];
};
export default sidebarConfig;
