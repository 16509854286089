import { Grid } from '@mui/material';
import React from 'react';
import { PATIENT_PROGRAM_STATUS } from '../../../common/Constants';
import useService from '../../../hooks/HookService';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { ReportData } from '../../../interfaces/Models';
import { IEmployeesFinancialReports } from '../../../interfaces/Reports';
import StatisticsService from '../../../services/StatisticsService';
import { getStartEndDateDep } from '../../../utils/Helper';
import ClinicPatientsDonutWidget from './ClinicPatientsDonutWidget';
import ClinicsAmountsWidget from './ClinicsAmountsWidget';
import ClinicsFinancialSummaryWidget from './ClinicsFinancialSummaryWidget';
import ClinicsPatientsBarChartWidget from './ClinicsPatientsBarChartWidget';
import EmployeesFinancialSummaryWidget from './EmployeesFinancialSummaryWidget';
import EmployeesReportsWidgets from './EmployeesReportsWidgets';
import ProgramStatusBarChartWidget from './ProgramStatusBarChartWidget';
import ProgramsStatusDonutWidget from './ProgramsStatusDonutWidget';
import ProgramsQuantityWidget from './ProgramsQuantityWidget';

const ReportsWidgets = () => {
  const { pageInfo } = useSearchFilter();

  const { from, to, range, month, year } = pageInfo;

  const clinicsPatientsData = useService<ReportData>({
    service: StatisticsService.getClinicPatientsChartData({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
    }),
    deps: [from, to, range, month, year],
  });

  const programsStatusData = useService<ReportData>({
    service: StatisticsService.getProgramsChartData({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
    }),
    deps: [from, to, range, month, year],
  });

  const visitsFinancialSummary = useService<IEmployeesFinancialReports>({
    service: StatisticsService.getEmployeesFinancialChartData({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
    }),
    deps: [from, to, range, month, year],
  });

  const clinicsFinancialSummary = useService<ReportData>({
    service: StatisticsService.getClinicsFinancialReports({
      fromDate: getStartEndDateDep(pageInfo),
      toDate: getStartEndDateDep(pageInfo, true),
      status: `${PATIENT_PROGRAM_STATUS.DONE},${PATIENT_PROGRAM_STATUS.ACTIVE}`,
    }),
    deps: [from, to, range, month, year],
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <ClinicPatientsDonutWidget {...clinicsPatientsData} />
      </Grid>
      <Grid item xs={12} md={3}>
        <ClinicsPatientsBarChartWidget {...clinicsPatientsData} />
      </Grid>
      <Grid item xs={12} md={3}>
        <ProgramsStatusDonutWidget {...programsStatusData} />
      </Grid>
      <Grid item xs={12} md={3}>
        <ProgramStatusBarChartWidget {...programsStatusData} />
      </Grid>
      <Grid item xs={12}>
        <ProgramsQuantityWidget />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <EmployeesFinancialSummaryWidget {...visitsFinancialSummary} />
      </Grid>

      <Grid item xs={12} md={9} lg={9}>
        <EmployeesReportsWidgets {...visitsFinancialSummary} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <ClinicsFinancialSummaryWidget {...clinicsFinancialSummary} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <ClinicsAmountsWidget {...clinicsFinancialSummary} />
      </Grid>
    </Grid>
  );
};

export default ReportsWidgets;
