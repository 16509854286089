import React from 'react';
import { useTranslation } from 'react-i18next';
import useAccess from '../../hooks/useAccess';
import { AbilitySubjects } from '../../casl/ability';
import Page from '../../components/Page';
import { Card, CardHeader } from '@mui/material';
import LeumitClinicReportForm from './clinicReport/LeumitClinicReportForm';
import MissingLeumitReferenceNumberRecordsTableContainer from './missing-records/MissingLeumitRecordsTableContainer';

export default function LeumitReportsPage() {
  const { t } = useTranslation();
  useAccess('read', AbilitySubjects.ADMIN_ABILITY);
  return (
    <Page title={t('report.leumitReport')}>
      <>
        <Card sx={{ p: 3 }}>
          <CardHeader title={t('report.leumitReport')} />
          <LeumitClinicReportForm />
        </Card>
        <MissingLeumitReferenceNumberRecordsTableContainer />
      </>
    </Page>
  );
}
