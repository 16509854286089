/* eslint-disable no-unused-vars */
import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { USER_ROLES } from '../common/Constants';
import { IUser } from '../interfaces/Models';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
export enum AbilitySubjects {
  EMPLOYEES_ABILITY = 'EMPLOYEES_ABILITY',
  ADMIN_ABILITY = 'ADMIN_ABILITY',
  ALL = 'ALL',
  ADMIN_AND_HMA_ABILITY = 'ADMIN_AND_HMA_ABILITY',
}

export type Subject = IUser | AbilitySubjects;

export type AppAbility = Ability<[Actions, Subject]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(user: IUser | null) {
  const { can, rules } = new AbilityBuilder(AppAbility);
  const userRole = user?.role;
  const userPosition = user?.position;

  can('read', AbilitySubjects.ALL);

  // Abilities for employees
  if (userRole === USER_ROLES.EMPLOYEE) {
    // Abilities for employees with position 'HMA'
    can('read', AbilitySubjects.EMPLOYEES_ABILITY);
    if (userPosition === 'HMA') {
      can('update', AbilitySubjects.ADMIN_AND_HMA_ABILITY);
    }
  }

  // Abilities for admins
  if (userRole === USER_ROLES.ADMIN) {
    can('read', AbilitySubjects.ADMIN_ABILITY);
    can('update', AbilitySubjects.ADMIN_ABILITY);
    can('create', AbilitySubjects.ADMIN_ABILITY);
    can('delete', AbilitySubjects.ADMIN_ABILITY);
    can('manage', AbilitySubjects.ADMIN_ABILITY);
    can('update', AbilitySubjects.ADMIN_AND_HMA_ABILITY);
    can('update', AbilitySubjects.ALL);
  }

  return rules;
}

export function buildAbilityFor(user: IUser | null): AppAbility {
  return new AppAbility(defineRulesFor(user), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: (object) => object!.type,
  });
}
