import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { IUserFlat } from '../../../interfaces/Models';
import { userAtom } from '../../../atoms/userAtom';
import ErrorMessage from '../../../components/ErrorMessage';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import { StyledDivider } from '../../../styles/FormFieldsStyles';
import FormikAutocomplete from '../../../components/Form/FormikAutocomplete';
import { TextField } from 'formik-mui';
import { Field } from 'formik';

interface Props {
  employees: IUserFlat[];
  isLoadingEmployees: boolean;
  error: boolean;
  errorMessage: string;
}
export default function ReferralFormFields({
  employees,
  isLoadingEmployees,
  error,
  errorMessage,
}: Props) {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }
  const getUserLabel = (user: IUserFlat): string => user.fullName;

  const isUserAdmin = checkIfUserIsAdmin(user);

  return (
    <Stack width={'100%'} gap={2} sx={{ pt: 2 }}>
      {isUserAdmin && (
        <FormikAutocomplete
          options={employees}
          getOptionLabel={getUserLabel}
          isLoading={isLoadingEmployees}
          name="employee"
          label={t('employee')}
        />
      )}
      <Field fullWidth component={TextField} type="text" label={t('title')} name="title" />
      <Field
        fullWidth
        component={TextField}
        rows={5}
        multiline
        type="text"
        label={t('body')}
        name="body"
      />
      <StyledDivider />
    </Stack>
  );
}
