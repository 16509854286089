import { theme } from '../theme';

export const ADJUSTMENT_REPEATED = 'ADJUSTMENT_REPEATED';

export const DEVELOPMENT_CLOUDINARY_CLOUD_NAME = 'dlbc9hk49';
export const DEVELOPMENT_CLOUDINARY_UPLOAD_PRESET = 'vnxry341';

export const PRODUCTION_CLOUDINARY_CLOUD_NAME = 'dudj9ax8u';
export const PRODUCTION_CLOUDINARY_UPLOAD_PRESET = 'gjx6pzqb';
export const isProduction = process.env.NODE_ENV === 'production';

export const USER_ROLES = { SUPER_ADMIN: 'SUPER_ADMIN', ADMIN: 'ADMIN', EMPLOYEE: 'EMPLOYEE' };

export const EMPLOYEE_POSITIONS = {
  HMA: 'HMA',
  HMB: 'HMB',
  HMD: 'HMD',
  HMK: 'HMK',
  HMM: 'HMM',
  HMP: 'HMP',
  HMS: 'HMS',
};

export type ColorObject = {
  [key: string]: string;
};

export const VISIT_COLOR: ColorObject = {
  HMA: '#5BE584',
  HMB: '#84A9FF',
  HMD: '#74CAFF',
  HMK: '#54D62C',
  HMM: '#FFE16A',
  HMP: '#FFA48D',
  HMS: '#9E86FF',
  HHD: '#FFE16A',
};

export const VISIT_STATUS_COLOR: ColorObject = {
  PENDING: 'info',
  CANCELED: 'error',
  DONE: 'success',
  SENT_TO_CLINIC: 'warning',
};

export const CLINICS_RELEASE_REPORT_COLORS: ColorObject = {
  מאוחדת: 'orange',
  לאומית: 'success',
};

export const PROGRAM_RELEASE_STATUS_COLOR: ColorObject = {
  CONTINUE: 'success',
  NOT_DECIDED: 'default',
  RELEASE: 'warning',
  waiting_for_extend_approval: 'info',
  release_sent: 'primary',
  waiting_to_hospis_move: 'secondary',
  hospital: 'error',
  wait_for_final_apProval: 'primary',
  furtherTreatmentWaitingApproval: 'info',
  sentReleaseCloseProgram: 'primary',
  extensionApprovedAfterPackage: 'secondary',
  extensionApprovedAfterSecondPackage: 'warning',
  patientSuitableToTransferHospice: 'info',
  patientSuitableToTransferRehabilitation: 'info',
  requestToHospiceTransferIsWaiting: 'warning',
  requestToRehabilitationTransferIsWaiting: 'warning',
  deceased: 'error',
  transferToRehabilitation: 'warning',
  previouslyApprovedExtension: 'info',
  continuesIndefinitely: 'success',
};

export const VISIT_STATUS_TO_HEBREW: ColorObject = {
  PENDING: 'לא בוצע',
  CANCELED: 'בוטל',
  DONE: 'בוצע',
  SENT_TO_CLINIC: 'נשלח לקופה',
  MISSING: 'טרם בוצעו',
};

export const PROGRAMS_COLOR: ColorObject = {
  ACTIVE: 'info',
  DONE: 'success',
  CANCELED: 'error',
};

export const reportTypes = {
  HMK_FIRST: 'HMK_FIRST',
  HMK_REPEATED: 'HMK_REPEATED',
  HMK_RELEASE: 'HMK_RELEASE',
  HMB_FIRST: 'HMB_FIRST',
  HMB_GENERAL: 'HMB_GENERAL',
  HMB_REPEATED: 'HMB_REPEATED',
  HMB_RELEASE: 'HMB_RELEASE',
  HMD_FIRST: 'HMD_FIRST',
  HMD_REPEATED: 'HMD_REPEATED',
  HMD_RELEASE: 'HMD_RELEASE',
  HMA_FIRST: 'HMA_FIRST',
  HMA_REPEATED: 'HMA_REPEATED',
  HMA_RELEASE: 'HMA_RELEASE',
  HMM_FIRST: 'HMM_FIRST',
  HMM_REPEATED: 'HMM_REPEATED',
  HMM_RELEASE: 'HMM_RELEASE',
  HMP_GENERAL: 'HMP_GENERAL',
  HMP_FIRST: 'HMP_FIRST',
  HMP_REPEATED: 'HMP_REPEATED',
  HMP_RELEASE: 'HMP_RELEASE',
  HMS_FIRST: 'HMS_FIRST',
  HMS_REPEATED: 'HMS_REPEATED',
  HMS_RELEASE: 'HMS_RELEASE',
  HMP_TABLE_FIRST: 'HMP_TABLE_FIRST',
  HMP_TABLE_RELEASE: 'HMP_TABLE_RELEASE',
  HMM_ADJUSTMENT_FIRST: 'HMM_ADJUSTMENT_FIRST',
  HMM_ADJUSTMENT_REPEATED: 'HMM_ADJUSTMENT_REPEATED',
  HMM_ADJUSTMENT_SECOND: 'HMM_ADJUSTMENT_SECOND',
  HMM_ADJUSTMENT_THIRD: 'HMM_ADJUSTMENT_THIRD',
};

export const VISIT_STATUS = {
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  DONE: 'DONE',
  SENT_TO_CLINIC: 'SENT_TO_CLINIC',
};

export const PATIENT_PROGRAM_STATUS = {
  ACTIVE: 'ACTIVE',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
};

export const PATIENT_PROGRAM_STATUS_TO_HEBREW: ColorObject = {
  ACTIVE: 'פעיל',
  DONE: 'בוצע',
  CANCELED: 'בוטל',
};

export const practiceInputState = {
  category: '',
  subCategory: '',
  fields: [],
  categoryErrors: '',
  subCategoryErrors: '',
  added: false,
};

export const EMPLOYEE_FAMILY_STATUS = {
  MARRIED: 'married',
  WIDOWED: 'widowed',
  SEPARATED: 'separated',
  DIVORCED: 'divorced',
  SINGLE: 'single',
};

export const DATE_RANGE_FILTERS = {
  CURRENT_MONTH: 'CURRENT_MONTH',
  NEXT_MONTH: 'NEXT_MONTH',
  PREVIOUS_MONTH: 'PREVIOUS_MONTH',
  SPECIFIC_MONTH: 'SPECIFIC_MONTH',
  CUSTOM: 'CUSTOM',
};

export const FILTERS = {
  SEARCH: 'SEARCH',
  DATE_RANGE: 'DATE_RANGE',
  STATUS_FILTER: 'STATUS_FILTER',
  FILTERS_LIST: 'FILTERS_LIST',
  TITLE_FILTER: 'TITLE_FILTER',
  CLINIC_FILTER: 'CLINIC_FILTER',
};

export const CLINIC_LIST = {
  MEUHEDET: 'מאוחדת',
  LEUMIT: 'לאומית',
};

export const URL_QUERY_PARAMS = {
  PAGE: 'page',
  LIMIT: 'limit',
  SORT: 'sort',
  SEARCH: 'search',
  FROM: 'from',
  TO: 'to',
  STATUS: 'status',
  RANGE: 'range',
  TITLE: 'title',
  EMPLOYEE: 'employee',
  CLINIC: 'clinic',
  MONTH: 'month',
  YEAR: 'year',
};

export const HOSPITAL_DAYS_TYPE = {
  HOSPITAL: 'hospital',
  NO_PAYMENT: 'noPayment',
};

export const PATIENT_PROGRAM_RELEASE_STATUS = {
  CONTINUE: 'CONTINUE',
  RELEASE: 'RELEASE',
  NOT_DECIDED: 'NOT_DECIDED',
  hospital: 'hospital',
  wait_for_final_apProval: 'wait_for_final_approval',
  deceased: 'deceased',
  continuesIndefinitely: 'continuesIndefinitely',
  furtherTreatmentWaitingApproval: 'furtherTreatmentWaitingApproval',
  sentReleaseCloseProgram: 'sentReleaseCloseProgram',
  extensionApprovedAfterPackage: 'extensionApprovedAfterPackage',
  extensionApprovedAfterSecondPackage: 'extensionApprovedAfterSecondPackage',
  patientSuitableToTransferHospice: 'patientSuitableToTransferHospice',
  patientSuitableToTransferRehabilitation: 'patientSuitableToTransferRehabilitation',
  requestToHospiceTransferIsWaiting: 'requestToHospiceTransferIsWaiting',
  requestToRehabilitationTransferIsWaiting: 'requestToRehabilitationTransferIsWaiting',
};

export const VAT = 1.17;

export const CLINICS_COLORS: ColorObject = {
  מאוחדת: theme.palette.info.light,
  לאומית: theme.palette.success.light,
};

export const STRONG_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;

export const BIG_TABLE_SIZES = [50, 100, 150];

export const TABS_NAMES = {
  PATIENT: 'PATIENT',
  PROGRAM: 'PROGRAM',
  EMPLOYEE: 'EMPLOYEE',
  PROFILE: 'PROFILE',
};

export const RESET_PAGE_QUERY = { name: URL_QUERY_PARAMS.PAGE, value: '1' };

export const USER_STATUS_FILTER_VALUES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const PROGRAM_PAYMENT_MODE = {
  PROGRAM_LEVEL: 'PROGRAM_LEVEL',
  VISIT_LEVEL: 'VISIT_LEVEL',
  FIXED_PRICE: 'FIXED_PRICE',
};

export const REPORT_TYPE_OPTIONS = [{ value: 'physical' }, { value: 'hybrid' }];

export const ADJUSTMENT = 'ADJUSTMENT';
export const PHONE = 'PHONE';
