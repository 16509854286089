/* eslint-disable new-cap */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../components/ErrorMessage';
import Table from '../../components/Table';
import useService from '../../hooks/HookService';
import { IUserFlat } from '../../interfaces/Models';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';
import { Column, Row } from 'react-table';
import DialogActions from '../../components/DialogActions';
import FetcherService from '../../hooks/FetcherService';
import { getEmployeesColumns } from '../../common/columns/Employees';
import { USER_STATUS_FILTER_VALUES } from '../../common/Constants';
import OverlaySpinner from '../../components/OverlaySpinner';
import { getPageNumberFromUrlParam, handleTableAtomChange } from '../../utils/Helper';
import useSearchFilter from '../../hooks/useSearchFilter';
import UserTableHeader from './UserTableHeader';

const UserTable: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pageInfo } = useSearchFilter();
  const [openResetModal, setOpenResetModal] = useState<boolean>(false);
  const [openBlockEmployeeModal, setOpeBlockEmployeeModal] = useState<boolean>(false);
  const [openActivateEmployeeModal, setOpenActivateEmployeeModal] = useState<boolean>(false);
  const [record, setRecord] = useState<IUserFlat | null>(null);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<number>(0);

  const {
    page,
    limit = 20,
    sort = '-createdAt',
    search,
    status = USER_STATUS_FILTER_VALUES.ACTIVE,
  } = pageInfo;
  const { isLoading, data, additionalData, loadingNumber, error, errorMessage } = useService<
    IUserFlat[]
  >({
    service: UserService.getAll({
      page: getPageNumberFromUrlParam(page),
      limit: limit,
      search: search,
      sort: sort,
      active: status === USER_STATUS_FILTER_VALUES.ACTIVE ? true : false,
    }),
    deps: [page, limit, search, shouldTableUpdate, sort, status],
  });

  const onOpenActions = (value: IUserFlat) => {
    handleTableAtomChange('userId', value._id);
    navigate(`/app/user/edit/${value?._id}`);
  };

  const onResetPassword = (value: IUserFlat) => {
    setRecord(value);
    setOpenResetModal(true);
  };

  const blockEmployeeHandler = (value: IUserFlat) => {
    setRecord(value);
    setOpeBlockEmployeeModal(true);
  };

  const activateEmployeeHandler = (value: IUserFlat) => {
    setRecord(value);
    setOpenActivateEmployeeModal(true);
  };

  const columns = getEmployeesColumns(
    sort,
    onOpenActions,
    onResetPassword,
    blockEmployeeHandler,
    activateEmployeeHandler
  );

  const handleResetModalClose = () => {
    setOpenResetModal(false);
    setRecord(null);
  };

  const handleModalClose = () => {
    setOpeBlockEmployeeModal(false);
    setOpenActivateEmployeeModal(false);
    setRecord(null);
  };

  const blockEmployee = async () => {
    if (record?._id) {
      await FetcherService<IUserFlat>({
        service: UserService.edit(record?._id as string, {
          ...record,
          active: false,
        }),
        errorMessage: `${t('global.errors.deleteEmployee')} : ${t(
          'global.errors.deleteEmployeeDesc'
        )}`,
      });
      setOpeBlockEmployeeModal(false);
      setShouldTableUpdate((prev) => prev + 1);
    }
  };

  const activateEmployee = async () => {
    if (record?._id) {
      await FetcherService<IUserFlat>({
        service: UserService.activateOneById(record?._id as string),
      });
      setOpenActivateEmployeeModal(false);
      setShouldTableUpdate((prev) => prev + 1);
    }
  };

  const onRowClick = useCallback(
    (row: Row<IUserFlat>) => {
      const isActiveEmployees = status === USER_STATUS_FILTER_VALUES.ACTIVE;
      if (isActiveEmployees) {
        handleTableAtomChange('userId', row.original._id);
        navigate(`/app/user/${row.original._id}`);
      }
    },
    [status]
  );

  if (error) {
    return <ErrorMessage error={errorMessage} />;
  }

  if (isLoading && loadingNumber == 1) {
    return <OverlaySpinner />;
  }
  return (
    <>
      <ResetPasswordForm record={record} onClose={handleResetModalClose} open={openResetModal} />
      <DialogActions
        onClose={handleModalClose}
        open={openBlockEmployeeModal}
        dialog={`${t('user.blockUser')} ${record?.fullName} ?`}
        action={blockEmployee}
      />
      <DialogActions
        onClose={handleModalClose}
        open={openActivateEmployeeModal}
        dialog={`${t('user.activateUser')} ${record?.fullName} ?`}
        action={activateEmployee}
      />
      <Table
        columns={columns as Array<Column>}
        data={data || []}
        count={additionalData?.total || 0}
        pageInfo={{
          page: getPageNumberFromUrlParam(page),
          pageSize: Number(limit),
        }}
        onRowClick={onRowClick}
        isLoading={isLoading}
        showFilters={true}
        TableHeaderFilters={<UserTableHeader />}
      />
    </>
  );
};

export default UserTable;
