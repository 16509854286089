import { Box, Tab, Tabs as TabsContainer } from '@mui/material';
import React, { ReactElement } from 'react';
import TabPanel from '../components/TabPanel';
import { styled } from '@mui/system';
import { theme } from '../theme';
import { handleTableAtomChange } from '../utils/Helper';
import { useRecoilValue } from 'recoil';
import { tableAtom } from '../atoms/tableAtom';
import useSearchFilter from '../hooks/useSearchFilter';

const StyledBox = styled(Box, { name: 'StyledBox', slot: 'Wrapper' })({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
});

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ITab {
  label: string;
  component: ReactElement;
  icon: ReactElement;
}

interface TabsProps {
  tabs: ITab[];
  tabsName: string;
  actions?: ReactElement;
}
const Tabs = ({ tabs, tabsName, actions }: TabsProps) => {
  const tableSession = useRecoilValue(tableAtom);
  const [value, setValue] = React.useState(tableSession[tabsName] || 0);
  const { clearQueryParams } = useSearchFilter();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleTableAtomChange(tabsName, newValue);
    clearQueryParams();
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledBox>
        <TabsContainer
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabs.map((tab: ITab, idx: number) => {
            return (
              <Tab
                key={tab.label}
                label={tab.label}
                {...a11yProps(idx)}
                icon={tab.icon}
                iconPosition="start"
              />
            );
          })}

          {actions && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>{actions}</Box>
          )}
        </TabsContainer>
      </StyledBox>
      {tabs.map((tab: ITab, idx: number) => {
        return (
          <TabPanel key={`${tab.label}_${idx}`} value={value} index={idx}>
            {tab.component}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default Tabs;
