/* eslint-disable new-cap */
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { IPatientProgram, IUserFlat, ReportData } from '../../../interfaces/Models';
import { userAtom } from '../../../atoms/userAtom';
import { checkIfUserIsAdmin } from '../../../utils/Helper';
import FormModal from '../../../components/Modals/FormModal';
import Form from '../../../components/Form/FormikForm';
import programReferralService from '../../../services/programReferralService';
import { IProgramReferral } from '../../../interfaces/referral';
import FetcherService from '../../../hooks/FetcherService';
import ReferralFormFields from './ReferralFormFields';

interface Props {
  open: boolean;
  handleTableDataUpdate: () => void;
  handleCloseModal: () => void;
  program: IPatientProgram;
}
const ReferralForm = ({ open, handleCloseModal, handleTableDataUpdate, program }: Props) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [isLoadingForm, setIsLoading] = useState<boolean>(false);

  const [employees, setEmployees] = useState<IUserFlat[]>([]);
  const [isEmployeesDataFetched, setIsEmployeesDataFetched] = useState<boolean>(false);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const isUserAdmin = checkIfUserIsAdmin(user);

  useEffect(() => {
    if (!isEmployeesDataFetched && isUserAdmin && open && !isLoadingEmployees) {
      setIsLoadingEmployees(true);
      axios
        .get(`/api/user`, {
          params: {
            page: 0,
            limit: 1000,
          },
        })
        .then((motors) => {
          setIsEmployeesDataFetched(true);
          setEmployees(motors.data.data);
          setIsLoadingEmployees(false);
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setError(true);
        });
    }
  }, [open]);

  const Schema = Yup.object({
    employee: Yup.object()
      .shape({
        _id: Yup.string().required(t('clinic.form.required')),
      })
      .required(t('clinic.form.required')),
    title: Yup.string().required(t('clinic.form.required')),
    body: Yup.string().required(t('clinic.form.required')),
    program: Yup.string().required(t('clinic.form.required')),
  });

  const initialValues = useMemo(() => {
    return {
      referralData: {},
      employee: isUserAdmin
        ? undefined
        : {
            _id: user?._id,
          },
      program: program?._id,
    };
  }, [user, isLoadingEmployees]);

  const handleSubmit = async (values: ReportData) => {
    await FetcherService<any>({
      service: programReferralService.add(values as IProgramReferral),
      setLoading: setIsLoading,
    });
    handleCloseModal();
    handleTableDataUpdate();
  };

  return (
    <FormModal
      open={open}
      onClose={handleCloseModal}
      text={t('newReferral')}
      loading={isLoadingForm}
      maxWidth="lg"
      key={user?._id}
    >
      <Form
        onCancel={handleCloseModal}
        submit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Schema}
        cancelText={t('clinic.form.cancel')}
        submitText={t('clinic.form.add')}
      >
        <ReferralFormFields
          error={error}
          errorMessage={errorMessage}
          isLoadingEmployees={isLoadingEmployees}
          employees={employees}
        />
      </Form>
    </FormModal>
  );
};

export default ReferralForm;
