import React from 'react';
import { FormFieldsProps } from '../../../components/Form/FormikForm';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMedicine } from '../../../interfaces/Models';
import isReportMode from '../../../hooks/isReportMode';
import DiseaseAutoComplete from './DiseaseAutoComplete';
import MedicationsListField from '../../../components/MedicationsListField';

interface ChronicDiseasesFieldsProps extends FormFieldsProps {
  id: string;
  onChangeFormikValue: (field: string, value: any, shouldValidate?: boolean) => void;
  chronicMedicationsValues: Array<IMedicine>;
}
const ChronicDiseasesFields = ({
  touched,
  errors,
  id,
  chronicMedicationsValues,
  onChangeFormikValue,
}: ChronicDiseasesFieldsProps) => {
  const { t } = useTranslation();
  const isViewMode = isReportMode('view');

  return (
    <div id={id}>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.chronicDiseases.mainDiagnosis')}
      </Typography>
      <Grid container spacing={{ xs: 0, md: 4, lg: 4 }} id={id + '_Container'}>
        <Grid item xs={12} md={12} lg={12}>
          <DiseaseAutoComplete fieldName="mainDiagnosis" />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" gutterBottom align="left">
        {t('report.chronicDiseases.chronic')}
      </Typography>
      <Grid container spacing={{ xs: 0, md: 4, lg: 4 }} id={id + '_Container'}>
        <Grid item xs={12} md={12} lg={12}>
          <DiseaseAutoComplete fieldName="chronic" />
        </Grid>
      </Grid>
      <MedicationsListField
        isViewMode={isViewMode}
        id={id}
        fieldName={'data.chronicDiseases.chronicMedications'}
        value={chronicMedicationsValues || []}
      />
    </div>
  );
};

export default ChronicDiseasesFields;
